<template>
  <div class="text-center">
    <v-snackbar color="red" v-model="Snackbar" top light>
      {{ ServerMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" dark small v-bind="attrs" @click="snackbar">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data: () => ({
    multiLine: true,
  }),
  computed: {
    ServerMessage() {
      return this.$store.getters.ServerMessage;
    },
    Snackbar() {
      return this.$store.getters.Snackbar;
    },
  },
  methods: {
    snackbar() {
      this.$store.commit("setSnackbar", false);
    },
  },
  watch: {
    Snackbar(val) {
      if (val) {
        setTimeout(() => {
          this.$store.commit("setSnackbar", false);
        }, 3000);
      }
    },
  },
};
</script>