<template>
  <v-app>
    <v-app-bar-nav-icon
      v-if="getToken"
      class="menuBtn"
      @click.stop="drawer = !drawer"
    ></v-app-bar-nav-icon>
    <v-main>
      <router-view></router-view>
      <Loading v-if="loading" />
      <Snackbar />
      <v-navigation-drawer
        v-model="drawer"
        clipped
        clipped-right
        app
        class="sideNavMenu"
      >
        <div>
          <v-list light v-if="getToken">
            <v-list-item-group v-model="selectedItem" color="#d4b506">
              <v-list-item
                @click="navigate(item.navigate)"
                v-for="(item, i) in items"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-navigation-drawer>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "./components/Snackbar";
import Loading from "./components/Loading";
export default {
  name: "App",

  components: {
    Snackbar,
    Loading,
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    navigate(payload) {
      this.$router.push({ name: payload });
    },
  },

  data: () => ({
    drawer: false,
    selectedItem: 0,
    items: [
      {
        text: "All Users",
        icon: "mdi-file-chart-outline",
        navigate: "allUsers",
      },
      {
        text: "Investment",
        icon: "mdi-file-chart-outline",
        navigate: "Investment",
      },
      {
        text: "Investment Plan",
        icon: "mdi-file-chart-outline",
        navigate: "InvestmentPlan",
      },
      {
        text: "Withdrawal",
        icon: "mdi-file-chart-outline",
        navigate: "Withdrawal",
      },
      {
        text: "Admin",
        icon: "mdi-file-chart-outline",
        navigate: "Admin",
      },
      {
        text: "Messages",
        icon: "mdi-file-chart-outline",
        navigate: "Messages",
      },
      {
        text: "News Letter Sub.",
        icon: "mdi-file-chart-outline",
        navigate: "newsLetter",
      },
    ],
  }),
};
</script>
