export const Domain = "https://lxgminers.com/api/v1";
export const DomainImage = "https://lxgminers.com/api/";
export const DomainSocket = "https://lxgminers.com";

//export const Domain = "http://localhost:2000/api/v1";
//export const DomainImage = "http://localhost:2000/api/";
//export const DomainSocket = "http://localhost:2000";

export const Login = `${Domain}/admin/Login`;
export const AddStaff = `${Domain}/admin/AddStaff`;
export const GetAllUsers = `${Domain}/admin/GetAllUsers`;
export const GetAllAdmin = `${Domain}/admin/GetAllAdmin`;
export const GetAllInvestmentWithStatus = `${Domain}/admin/GetAllInvestmentWithStatus`;
export const GetInvestmentPlan = `${Domain}/investment/GetInvestmentPlan`;
export const ActivateInvestment = `${Domain}/investment/ActivateInvestment`;
export const CreateInvestmentPlan = `${Domain}/investment/CreateInvestmentPlan`;
export const AllUserWithdraw = `${Domain}/withdraw/AllUserWithdraw`;
export const ApproveWithdrawal = `${Domain}/withdraw/ApproveWithdrawal`;
export const GetContactMessage = `${Domain}/contact/GetContactMessage`;
export const GetNewsLetter = `${Domain}/contact/GetNewsLetter`;
export const LoginHistory = `${Domain}/admin/UserLoginHistory`;
export const AdminLoginHistory = `${Domain}/admin/AdminLoginHistory`;
export const EnableOrDisableAdmin = `${Domain}/admin/EnableOrDisableAdmin`;
export const EnableOrDisableUser = `${Domain}/admin/EnableOrDisableUser`;
export const EditInvestmentPlan = `${Domain}/investment/EditInvestmentPlan`;

export const SetWalletAddress = `${Domain}/withdraw/SetWalletAddress`;
export const EditAdminWalletAddress = `${Domain}/withdraw/EditAdminWalletAddress`;
export const SetReferralBonus = `${Domain}/withdraw/SetReferralBonus`;
export const UpdateReferralBonus = `${Domain}/withdraw/UpdateReferralBonus`;
export const GetReferralBonus = `${Domain}/withdraw/GetReferralBonus`;

export const ChangePassword = `${Domain}/admin/ChangePassword`;

export const ResetPassword = `${Domain}/admin/ResetPassword`;
export const ConfirmResetPassword = `${Domain}/admin/ConfirmResetPassword`;

export const FundUserBouns = `${Domain}/admin/FundUserBouns`;
export const FundUserAccount = `${Domain}/admin/FundUserAccount`;
