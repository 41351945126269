import {
  Login,
  GetAllUsers,
  GetAllAdmin,
  GetAllInvestmentWithStatus,
  GetInvestmentPlan,
  AllUserWithdraw,
  GetContactMessage,
  GetNewsLetter,
  ActivateInvestment,
  ApproveWithdrawal,
  CreateInvestmentPlan,
  AddStaff,
  LoginHistory,
  AdminLoginHistory,
  EnableOrDisableUser,
  EnableOrDisableAdmin,
  EditAdminWalletAddress,
  SetWalletAddress,
  EditInvestmentPlan,
  ChangePassword,
  SetReferralBonus,
  GetReferralBonus,
  UpdateReferralBonus,
  FundUserAccount,
  FundUserBouns,
} from "../APIs";

import axios from "axios";

const state = {
  loading: false,
  ServerMessage: "",
  snackbar: false,
  getUser: null,
  Token: "",
  tableLoading: false,
  allUsers: [],
  totalItems: 1,
  investments: [],
  getInvestmentPlan: [],
  withdraws: [],
  getContactMessage: [],
  getNewsLetter: [],
  getAllAdmin: [],
  LoginHistory: [],
  adminWalletAddress: null,
  ReferralBonus: null,
};
const getters = {
  ReferralBonus(state) {
    return state.ReferralBonus;
  },
  getLoginHistory(state) {
    return state.LoginHistory;
  },
  getAllAdmin(state) {
    return state.getAllAdmin;
  },
  getAdminWalletAddress(state) {
    return state.adminWalletAddress;
  },
  getNewsLetter(state) {
    return state.getNewsLetter;
  },
  getUser(state) {
    return state.getUser;
  },
  getContactMessage(state) {
    return state.getContactMessage;
  },
  getWithdraws(state) {
    return state.withdraws;
  },
  getInvestments(state) {
    return state.investments;
  },
  totalItems(state) {
    return state.totalItems;
  },
  getAllUser(state) {
    return state.allUsers;
  },
  getLoading(state) {
    return state.loading;
  },
  TableLoading(state) {
    return state.tableLoading;
  },
  ServerMessage(state) {
    return state.ServerMessage;
  },
  Snackbar(state) {
    return state.snackbar;
  },
  getToken(state) {
    return state.Token;
  },
  getInvestmentPlan(state) {
    return state.getInvestmentPlan;
  },
};
const mutations = {
  SetReferralBonus(state, payload) {
    state.ReferralBonus = payload;
  },
  setLoginHistory(state, payload) {
    state.LoginHistory = payload;
  },
  setGetAllAdmin(state, payload) {
    state.getAllAdmin = payload;
  },
  setGetNewsLetter(state, payload) {
    state.getNewsLetter = payload;
  },
  setGetContactMessage(state, payload) {
    state.getContactMessage = payload;
  },
  setTotalItems(state, payload) {
    state.totalItems = payload;
  },
  setSnackbar(state, payload) {
    state.snackbar = payload;
  },
  setServerMessage(state, payload) {
    state.ServerMessage = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setUser(state, payload) {
    state.getUser = payload;
  },
  setTableLoading(state, payload) {
    state.tableLoading = payload;
  },
  setSignOut(state, payload) {
    state.getUser = "";
    state.Token = "";
    payload.route.push({ name: "login" });
  },
  setToken(state, payload) {
    if (payload) {
      state.tokenExpired = /*8.64e7*/ 20000 + Date.now();
    }
    state.Token = payload;
  },
  setAllUsers(state, payload) {
    state.allUsers = payload;
  },
  setInvestments(state, payload) {
    state.investments = payload;
  },
  setGetInvestmentPlan(state, payload) {
    state.getInvestmentPlan = payload;
  },
  setWithdraws(state, payload) {
    state.withdraws = payload;
  },
  setAdminWalletAddress(state, payload) {
    state.adminWalletAddress = payload;
  },
};
const actions = {
  Login({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(`${Login}`, {
        userName: payload.userName,
        password: payload.password,
      })
      .then((res) => {
        commit("setUser", res.data.profile);
        commit("setToken", res.data.token);
        if (payload.redirect) {
          payload.route.push({ path: payload.redirect });
        } else {
          payload.route.push({ name: "allUsers" });
        }
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  GetAllAdmin({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllAdmin}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setGetAllAdmin", res.data.Users);
        commit("setAdminWalletAddress", res.data.AdminWalletAddress);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },

  GetAllUsers({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetAllUsers}?page=${payload.page}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setAllUsers", res.data.Users);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  GetNewsLetter({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetNewsLetter}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setGetNewsLetter", res.data.newsLetter);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  GetContactMessage({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetContactMessage}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setGetContactMessage", res.data.contactUsMessage);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  AdminLoginHistory({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${AdminLoginHistory}`,
        {
          userId: payload.userId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setLoginHistory", res.data.LoginHistory);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  LoginHistory({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${LoginHistory}`,
        {
          userId: payload.userId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setLoginHistory", res.data.LoginHistory);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  GetInvestmentPlan({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetInvestmentPlan}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setGetInvestmentPlan", res.data.investmentPlan);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  GetAllInvestmentWithStatus({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${GetAllInvestmentWithStatus}?page=${payload.page}`,
        {
          status: payload.status,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setInvestments", res.data.Investments);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  AllUserWithdraw({ commit }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${AllUserWithdraw}?page=${payload.page}`,
        {
          status: payload.status,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setWithdraws", res.data.Withdraws);
        commit("setTotalItems", res.data.count);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  ApproveWithdrawal({ commit, dispatch }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ApproveWithdrawal}?page=${payload.page}`,
        {
          withdrawId: payload.withdrawId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        payload.status = "Pending";
        dispatch("AllUserWithdraw", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  ActivateInvestment({ commit, dispatch }, payload) {
    commit("setTableLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ActivateInvestment}?page=${payload.page}`,
        {
          investmentId: payload.investmentId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        payload.status = "Pending";
        dispatch("GetAllInvestmentWithStatus", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setTableLoading", false);
      });
  },
  AddStaff({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${AddStaff}`,
        {
          email: payload.email,
          password: payload.password,
          userName: payload.userName,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        dispatch("GetAllAdmin", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  CreateInvestmentPlan({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${CreateInvestmentPlan}?page=${payload.page}`,
        {
          investmentName: payload.investmentName,
          dailyReturnRate: payload.dailyReturnRate,
          totalReturnRate: payload.totalReturnRate,
          lowestInvestmentAmount: payload.lowestInvestmentAmount,
          hightestInvestmentAmount: payload.hightestInvestmentAmount,
          duration: payload.duration,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        dispatch("GetInvestmentPlan", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  EnableOrDisableAdmin({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${EnableOrDisableAdmin}?page=${payload.page}`,
        {
          userId: payload.userId,
          action: payload.action,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        dispatch("GetAllAdmin", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  EnableOrDisableUser({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${EnableOrDisableUser}?page=${payload.page}`,
        {
          userId: payload.userId,
          action: payload.action,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        dispatch("GetAllUsers", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  EditAdminWalletAddress({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${EditAdminWalletAddress}`,
        {
          adminWalletAddressId: payload.adminWalletAddressId,
          litecoin: payload.litecoin,
          Ethereum: payload.Ethereum,
          Bitcoin: payload.Bitcoin,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        dispatch("GetAllAdmin", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  SetWalletAddress({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${SetWalletAddress}`,
        {
          litecoin: payload.litecoin,
          Ethereum: payload.Ethereum,
          Bitcoin: payload.Bitcoin,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        dispatch("GetAllAdmin", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  EditInvestmentPlan({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${EditInvestmentPlan}`,
        {
          ...payload,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        dispatch("GetInvestmentPlan", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  ChangePassword({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ChangePassword}`,
        {
          oldPassword: payload.oldPassword,
          newPassword: payload.newPassword,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        commit("setToken", "");
        commit("setUser", null);
        payload.route.push({ name: "login" });
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  SetReferralBonus({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${SetReferralBonus}`,
        {
          amount: payload.amount,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("GetReferralBonus", payload);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  UpdateReferralBonus({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${UpdateReferralBonus}`,
        {
          amount: payload.amount,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("GetReferralBonus", payload);
      })
      .catch((err) => {
        console.log(err);
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  GetReferralBonus({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetReferralBonus}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("SetReferralBonus", res.data.ReferralBonus);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  FundUserBouns({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${FundUserBouns}`,
        {
          amount: payload.amount,
          userId: payload.userId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        dispatch("GetAllUsers", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  FundUserAccount({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${FundUserAccount}`,
        {
          amount: payload.amount,
          userId: payload.userId,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        payload.page = 1;
        dispatch("GetAllUsers", payload);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
