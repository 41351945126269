import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/index.js";
import store from "./store/index";

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.forVisitors)) {
    if (store.getters.getUser) {
      next({
        path: "/Dashboard",
      });
    } else next();
  } else if (to.matched.some((record) => record.meta.forAuth)) {
    if (!store.getters.getUser) {
      next({
        path: "/",
        query: { redirect: to.path },
      });
    } else next();
  } else next();
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
