import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  // mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes: [
    {
      path: "/",
      name: "login",
      meta: { forVisitors: true },
      component: () => import("../pages/LoginPage.vue"),
    },
    {
      path: "/Dashboard",
      name: "Dashboard",
      meta: { forAuth: true },
      component: () => import("../pages/Dashboard.vue"),
      children: [
        {
          path: "/Dashboard/Users",
          name: "allUsers",
          meta: { forAuth: true },
          component: () => import("../pages/AllUsers.vue"),
        },
        {
          path: "/Dashboard/Investment",
          name: "Investment",
          meta: { forAuth: true },
          component: () => import("../pages/Investment.vue"),
        },
        {
          path: "/Dashboard/InvestmentPlan",
          name: "InvestmentPlan",
          meta: { forAuth: true },
          component: () => import("../pages/InvestmentPlan.vue"),
        },
        {
          path: "/Dashboard/Withdrawal",
          name: "Withdrawal",
          meta: { forAuth: true },
          component: () => import("../pages/Withdrawal.vue"),
        },
        {
          path: "/Dashboard/ChangePassword",
          name: "ChangePassword",
          meta: { forAuth: true },
          component: () => import("../pages/ChangePassword.vue"),
        },

        {
          path: "/Dashboard/Admin",
          name: "Admin",
          meta: { forAuth: true },
          component: () => import("../pages/Admin.vue"),
        },
        {
          path: "/Dashboard/Messages",
          name: "Messages",
          meta: { forAuth: true },
          component: () => import("../pages/Messages.vue"),
        },
        {
          path: "/Dashboard/newsLetter",
          name: "newsLetter",
          meta: { forAuth: true },
          component: () => import("../pages/newsLetter.vue"),
        },
      ],
    },
    {
      path: "*",
      name: "page404",
      component: () => import("../pages/Page404"),
    },
  ],
});
