<template>
  <div class="overlay">
    <div class="text-center">
      <div class="load">
        <v-card class="cardcolor" dark>
          <p class="dis">Processing</p>
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="value"
            color="black"
          >
            {{ value }}
          </v-progress-circular>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
      interval: {},
      value: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
  },
};
</script>

<style scoped>
.load {
  width: 300px;
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progresscolor {
  background-color: red !important;
}
.cardcolor {
  background-image: linear-gradient(to bottom, red, red) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 300px;
}
.v-progress-circular {
  margin: 1rem;
}
.v-progress-circular {
  margin: 1rem;
}
.dis {
  font-size: 20px;
  color: black;
}
</style>